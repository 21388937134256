// import { Link } from 'gatsby'
import Layout from '../layouts/layout'
import React from 'react'
import SEO from '../components/seo'
import partners from '../images/simsum-mp-dj.jpg'

const PageContent = props => (
  <Layout location={props.location}>
    <SEO title="New Page" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">

          <div id="headerImage1" className="headerImage py-5 mb-5">New Page</div>

          <div className="container">

            <div className="row flex-md-row">

              <div className="col-md-12">
                <p>Simple Summers was conceptualized, branded, designed and developed by three Sacramento parents.</p>

                <p>Prior to launching Simple Summers, we struggled to find summer camps that fit our kids' personalities, hit the budget and aligned with our schedule. We stressed over the arduous process of discovering new camps through a generic Google search. We loathed the task of creating a spreadsheet and hoping we got it all right. </p>

                <p>Simple Summers is the stress-free resource to find the best summer camps for your children — camps that fit your budget, schedule, and children's interests. Create an account. Add your camper(s). Build customized searches of the 700+ summer camps and thousands of camp sessions. Search by dates, camp types, price, and location. Create your family's summer camp schedule — and then add individual sessions to your online calendar.</p>

                <p>We are reinventing old traditions and adopting a new system, one that is more simple — saving you time and reducing stress.</p>

                <p>We do the hard work so you don't have to.</p>

                <p>Smile, it’s Simple.</p>

                <p>Meghan Phillips and DJ Waldow</p>

                <p>Questions/comments: Email us at <a href="mailto:hello@simplesummers.com">hello@simplesummers.com</a></p>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

  </Layout>
)

export default PageContent
